<template>
  <section>
    <b-tabs>
      <!-- Tab Carga de Archivos -->

      <b-tab title="Lineas Productivas">
        
      </b-tab>

      <!-- Tab Historico de Carga -->

      <b-tab title="Ingreso Nueva Linea">
        
      </b-tab>
    </b-tabs>
  </section>
</template>
<script>
import
{
  BRow,
  BCol,
  BCard,
  BButton,
  BCardHeader,
  BFormFile,
  BCardTitle,
  BCardSubTitle,
  BCardText,
  BCardBody,
  BFormSelect,
  BIconNodeMinusFill,
  BTabs,
  BSpinner,
  BAlert,
  BTab,
  BLink,
  VBPopover,
  VBTooltip,
  BFormGroup,
  BFormInvalidFeedback

} from "bootstrap-vue";



import AgGridTable from '@/views/brunacomponents/ag-grid-table/AgGridTable.vue'


export default {
  data()
  {
    return {



    }



  },
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BCardHeader,
    BFormFile,
    BCardTitle,
    BCardSubTitle,
    BCardText,
    BCardBody,
    BFormSelect,
    BTabs,
    VBPopover,
    VBTooltip,
    BTab,
    BLink,
    BAlert,
    BSpinner,
    //GoodTableBasic,
    BFormInvalidFeedback,
    BFormGroup,
    AgGridTable,

  },

  directives: {
    'b-tooltip': VBTooltip,
    "b-popover": VBPopover,
  },

  mounted()
  {

  },

  methods: {



  },
};
</script>

<style scoped>
.carga-data {
  margin-top: 4%;
}
</style>

